<template>
  <BaseButton icon="pi pi-trash" outlined type="danger" @click="callApi" :loading="isLoading" />
</template>

<script>
import useApi from "@use/useApi";

export default {
  props: {
    courseId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { isLoading, callApi } = useApi({
      module: "uc",
      method: "cartRemoveCourse",
      params: {
        courseId: props.courseId,
      },
      onSuccess: () => {
        emit("reload");
      },
    });

    return {
      isLoading,
      callApi,
    };
  },
};
</script>
