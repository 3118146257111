<template>
  <BaseFrontdoor>
    <BaseContainer fluid class="max-w-1024">
      <BaseHeadline size="1" fake="2" class="mt-4 mb-6">
        {{ $t("uc.cart.title") }}
      </BaseHeadline>

      <div v-if="isLoading" class="text-center py-4">
        <BaseProgressSpinner />
      </div>

      <div v-else-if="!cart.courses.length" class="text-center py-6">
        <BaseParagraph>{{ $t("uc.cart.noData") }}</BaseParagraph>

        <BaseButton @click="pushToCourses" label="uc.cart.goToCourses" />
      </div>

      <div v-else>
        <CartCourseList :courses="cart.courses" @reload="callApi" />
        <CartPrice :cart="cart" class="pt-3" />
        <div class="flex justify-content-between pt-3">
          <CartClearButton @reload="callApi" />
          <BaseButton label="uc.cart.checkout" @click="pushToCheckout" />
        </div>
      </div>
    </BaseContainer>
  </BaseFrontdoor>
</template>

<script>
import CartCourseList from "./partials/CartCourseList";
import BaseFrontdoor from "@/modules/base-components-bc/Base/BaseFrontdoor";
import { getPriceLabel } from "@/modules/user-course-uc/helper/GetPriceLabel";
import useApi from "@use/useApi";
import { ref } from "@vue/runtime-dom";
import CartPrice from "../partials/CartPrice";
import { useRouter } from "vue-router";
import CartClearButton from "./partials/CartClearButton";

export default {
  components: {
    CartClearButton,
    CartPrice,
    BaseFrontdoor,
    CartCourseList,
  },
  setup() {
    const router = useRouter();

    const cart = ref({
      courses: [],
    });

    const { isLoading, docs, callApi } = useApi({
      module: "uc",
      method: "showCart",
      onMount: true,
      onSuccess: () => {
        cart.value = docs.value.data;
      },
    });

    const pushTo = (name) => {
      try {
        router.push({
          name,
        });
      } catch (error) {
        return;
      }
    };

    const pushToCheckout = () => {
      pushTo("CartCheckout");
    };

    const pushToCourses = () => {
      pushTo("Courses");
    };

    return {
      getPriceLabel,
      isLoading,
      callApi,
      cart,
      pushToCheckout,
      pushToCourses,
    };
  },
};
</script>
