<template>
  <BaseButton :loading="isLoading" @click="callApi" label="uc.cart.clear" outlined />
</template>

<script>
import useApi from "@use/useApi";

export default {
  name: "CartClearButton",
  setup(props, { emit }) {
    const { isLoading, callApi } = useApi({
      module: "uc",
      method: "clearCart",
      onSuccess: () => emit("reload"),
    });

    return {
      isLoading,
      callApi,
    };
  },
};
</script>
