<template>
  <BaseRow>
    <BaseCol cols="12" v-for="(course, index) in courses" :key="index" class="mb-3">
      <BaseRow :no-gutter="true">
        <BaseCol cols="12" lg="3">
          <CourseThumbnail :course="course" />
        </BaseCol>

        <BaseCol cols="12" lg="9" class="pl-6">
          <div class="flex justify-content-between">
            <div>
              <BaseHeadline size="3" fake="3" class="mb-3" :noMargin="true">
                {{ course.title }}
              </BaseHeadline>

              <CourseTopics :course-topics="course.courseTopics" />

              <p style="color: var(--gray-500)" class="my-3">
                {{ getTeacherName(course.teacher) }}
              </p>

              <RemoveCartItemButton :course-id="course.id" @reload="reload" />
            </div>
            <div class="flex align-items-center">
              <p class="text-primary mr-3">
                {{ getPriceLabel(course.price) }}
              </p>
            </div>
          </div>
        </BaseCol>
      </BaseRow>
    </BaseCol>
  </BaseRow>
</template>

<script>
import CourseTopics from "@/modules/haw-components-hac/Base/CourseTopics";
import { getTeacherName } from "@/modules/user-course-uc/helper/GetTeacherName";
import { getPriceLabel } from "@/modules/user-course-uc/helper/GetPriceLabel";
import CourseThumbnail from "@/modules/user-course-uc/components/CourseThumbnail/CourseThumbnail";
import RemoveCartItemButton from "./RemoveCartItemButton";

export default {
  name: "CartCourseList",
  components: { RemoveCartItemButton, CourseThumbnail, CourseTopics },
  props: {
    courses: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const reload = () => {
      emit("reload");
    };

    return { getTeacherName, getPriceLabel, reload };
  },
};
</script>
